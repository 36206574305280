
export const TreeSpeciesColors: { [key: string]: string } = {
    "Дугласия (лжетсуга) Мензиеза": "#37A603",
    "Ель": "#37A603",
    "Ель карликовая": "#37A603",
    "Ель голубая": "#37A603",
    "Кедр": "#055902",
    "Кедровый стланик": "#055902",
    "Лиственница": "#4BA651",
    "Пихта": "#4BA651",
    "Сосна": "#055902",
    "Сосна горная": "#055902",
    "Сосна карликовая": "#055902",
    "Хвойный кустарник": "#7EBF88",
    "Абрикос": "#62BF04",
    "Акация": "#62BF04",
    "Бархат амурский": "#818C30",
    "Береза": "#818C30",
    "Береза повислая": "#818C30",
    "Береза даурская": "#818C30",
    "Береза Эрмана": "#818C30",
    "Боярышник": "#3E5902",
    "Боярышник перистонадрезанный": "#3E5902",
    "Вяз": "#62BF04",
    "Груша": "#58A60A",
    "Дуб": "#58A60A",
    "Дуб красный": "#58A60A",
    "Ива": "#58A60A",
    "Ива белая": "#7EBF88",
    "Ирга": "#58A60A",
    "Клен": "#58A60A",
    "Липа": "#58A60A",
    "Мелкоплодник": "#58A60A",
    "Ольха": "#58A60A",
    "Орех": "#58A60A",
    "Осина": "#58A60A",
    "Осина пирамидальная": "#58A60A",
    "Рябина": "#4BA651",
    "Рябина плакучая": "#4BA651",
    "Слива": "#4BA651",
    "Тополь": "#4D5922",
    "Тополь белый": "#4D5922",
    "Тополь пирамидальный": "#4D5922",
    "Черемуха": "#62BF04",
    "Черемуха Маака": "#62BF04",
    "Черемуха виргинская": "#62BF04",
    "Яблоня": "#58A60A",
    "Яблоня ягодная": "#58A60A",
    "Яблоня пурпурная": "#58A60A",
    "Ясень": "#58A60A",
    "Лиственный кустарник": "#C1D90D",
    "Другое": "#F2B705",
};

export const DefaultTreeColor = "#62BF04";//"#ff0000";

export const DefaultClusterColor = "#fefe00";